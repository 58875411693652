<template>
    <section>
        <div class="container">
            <TitleBar title="Satınalma Talebi Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-3" label="ID">
                    <b-input v-model="purchaserequests.filters.id"></b-input>
                </b-field>
                <b-field class="column is-3" label="Açıklama">
                    <b-input v-model="purchaserequests.filters.name"></b-input>
                </b-field>
                <b-field class="column is-3" label="Oluşturan">
                    <UserAutofill v-model="purchaserequests.filters.createdby" />
                </b-field>
                <b-field class="column is-3" label="Oluşturma Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="purchaserequests.filters.createdat" range> </b-datepicker>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Satınalma Talebi Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="purchaserequests_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="purchaserequests_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="purchaserequests_ref" disabled/>
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!purchaserequests.selected.id" @click="purchaserequests_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!purchaserequests.selected.id" @click="purchaserequests_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!purchaserequests.selected.id" @click="purchaserequests_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="purchaserequests_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="purchaserequests_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :data="purchaserequests.data"
                    :total="purchaserequests.total"
                    :mobile-cards="false"
                    :current-page.sync="purchaserequests.page"
                    :selected.sync="purchaserequests.selected"
                    :sort-multiple-data.sync="purchaserequests.sorts"
                    :loading="$apollo.queries.readPurchaseRequests.loading"
                    @dblclick="purchaserequests_show"
                    @sort="purchaserequests_sort"
                    @sorting-priority-removed="purchaserequests_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">
                            Satınalma talebi kaydı bulunamadı.
                        </div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="name" label="Açıklama" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                    <b-table-column field="issuedby" label="Oluşturma Tarihi" v-slot="props">{{
                        new Date(props.row.createdat).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                        })
                    }}</b-table-column>
                    <b-table-column field="issuedby" label="Oluşturan" v-slot="props">{{ props.row.createdby.name }}</b-table-column>
                    <b-table-column field="status" label="Durum" v-slot="props" sortable>
                        <b-tag :type="statusType(props.row.status)">
                            {{ props.row.status }}
                        </b-tag></b-table-column
                    >
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import UserAutofill from "@/components/UserAutofill.vue";

export default {
    name: "PurchaseRequests",
    components: {
        TitleBar,
        UserAutofill,
    },
    data: () => ({
        modi: "",
        purchaserequests: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                createdby: {},
                createdat: [],
            },
            selected: {},
        },
    }),
    computed: {
        statusType() {
            return (status) => (status == "Açık" ? "is-danger is-light" : status == "Askıda" ? "is-warning is-light" : status == "Kapalı" ? "is-success is-light" : status == "İptal" ? "is-light" : "is-light");
        },
    },
   mounted(){
        this.purchaserequests_read();
    },
    methods: {
        purchaserequests_read() {
            this.$apollo.queries.readPurchaseRequests.start();
            this.$apollo.queries.readPurchaseRequests.refetch();
        },
        purchaserequests_new() {
            this.$router.push({ path: "/satinalma/talep/ekle" });
        },
        purchaserequests_ref() {
            this.$router.push({
                path: "/satinalma/talep/ekle",
                query: { id: this.purchaserequests.selected.id, modi: "ref" },
            });
        },
        purchaserequests_show() {
            this.$router.push({
                path: "/satinalma/talep/detay",
                query: { id: this.purchaserequests.selected.id, modi: "show" },
            });
        },
        purchaserequests_edit() {
            this.$router.push({
                path: "/satinalma/talep/detay",
                query: { id: this.purchaserequests.selected.id, modi: "edit" },
            });
        },
        purchaserequests_delete() {},
        purchaserequests_print() {},
        purchaserequests_export() {},
        purchaserequests_sort(field, order) {
            this.purchaserequests_nosort(field);
            this.purchaserequests.sorts.push({ field, order });
        },
        purchaserequests_nosort(field) {
            this.purchaserequests.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        },
    },
    apollo: {
        readPurchaseRequests: {
            query: gql`
                query readPurchaseRequests($config: String) {
                    readPurchaseRequests(config: $config) {
                        id
                        name
                        status
                        createdby {
                            id
                            name
                        }
                        createdat
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.purchaserequests.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.purchaserequests.filters) if (!this.purchaserequests.filters[filter]) delete this.purchaserequests.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.purchaserequests.take,
                        filters: this.purchaserequests.filters,
                        skip: (this.purchaserequests.page - 1) * this.purchaserequests.take,
                    }),
                };
            },
            update(data) {
                this.purchaserequests.data = data.readPurchaseRequests;
            },
        },
    },
};
</script>
